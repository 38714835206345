import React, { useState, useEffect } from "react";
import * as AzureService from "../../api/ticketsApiAzure";
import TimelineAdo from "./TimelineAdo";
import ToolBarAdo from "./ToolBar_ADO";
import * as tConst from "../../common/Constants";
import store from 'store2';
import LoginAdo from "./LoginAdo2";


//https://medium.com/weekly-webtips/implementing-a-rest-api-with-react-hooks-using-patterns-2ea1476e2a05


const TimelinePageAdo = ({auth, updateAuth}) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject,setSelectedProject] = useState(store.get("SelectedProject")); // "Test for oyvindh"
    const [items, setItems] = useState([]);
    const [itemIds, setItemsIds] = useState([]);
    const [openOnClickStatus, setOpenOnClickStatus] = useState(store.get("openOnClickStatus"));
    const [isLoaded, setIsLoaded] = useState(false);
    const [itemQuery, setItemQuery] = useState({query: "Select [System.Id] , [System.Title], [System.State], [Custom.EndIteration], [Custom.StartIteration] From WorkItems Where [Custom.EndIteration] <> '' AND [State] <> 'Closed' and [System.TeamProject] = @project order by [Custom.PlanningPriority] asc, [Custom.StartIteration] asc, [System.CreatedDate] asc"});
    const [refresh, setRefresh] = useState();
    const [showChartMenu, setShowChartMenu] = useState(false);
    const [viewId, setViewId] = useState(store.get("SelectedViewId"));
    const [assigneeList, setAssigneeList] = useState([]);
    const [assigneeId, setAssigneeId] = useState(tConst.ADO_ASSIGNED_ALL);
    const [teamsId, setTeamsId] = useState(tConst.VIEW_ALL_TEAMS);

    const [hideProjects, setHideProjects] = useState((store.get("hideProjects") ? JSON.parse(store.get("hideProjects")):[]));
    //#region useEffects

   //#region Project
    useEffect(() => {
        setIsLoaded(false);
        if (auth && auth.un!== "" && auth.pw !== ""){

            AzureService.getProjects(auth).then(async (response) => 
            {
                setProjects(response.value.map(project => {return {label: project.name, id: ""+project.id, name: project.name, value: project.id}}));
                setIsLoaded(true);
            }).catch((error) => {
                updateAuth({un:"", pw: ""})
            });   
        }
    }, [auth, updateAuth]);
    //#endregion Project

    //#region  WorkItems
    useEffect(() => {
        setIsLoaded(false);

        const _selectedProject = projects ? projects.filter((filt) => {
            return filt.value === selectedProject}): null;
            
        const _selectedProjectName = _selectedProject && _selectedProject[0] ? _selectedProject[0].name : "";
        console.log("_selectedProjectName: "+_selectedProjectName);

        setItems([]);

        if (auth && auth.un!== "" && auth.pw !== ""){

            AzureService.getItemsList(auth, itemQuery, _selectedProjectName).then(
                async (response) => 
                    {
                        let ids = response.workItems.map((item) => item.id)
                        setItemsIds(ids);
                    });
        }
    }, [auth, itemQuery, selectedProject, refresh, projects]);


    useEffect(() => {


        if (auth && auth.un!== "" && auth.pw !== "" && itemIds.length >0) {



            let first = 0;
            let batchSize = 200;
            let pageIds = null;
            while (first< itemIds.length) {           

                pageIds = itemIds.slice(first,first+batchSize);
                AzureService.getItems(auth, pageIds).then(
                    async (response) => 
                    {
                        const tempItems = response.value.map(item => {
                            const pp = item.fields[tConst.AF_PLANNING_PRIORITY]
                            item.planningPri = ((pp) ? 1*pp : 99999 )
                            const _assignee = item.fields[tConst.AF_ASSIGNED_TO] ? item.fields[tConst.AF_ASSIGNED_TO].displayName : tConst.ADO_UNASSIGNED;
                            item.Assignee = _assignee;
                            const _tags = item.fields[tConst.AF_TAGS];
                            if (_tags) {
                                const _teams = _tags.split(";").filter((tag) => {
                                    return tConst.AF_TEAMS.indexOf(tag.trim()) > -1 
                                });
                                console.log("2024-12-03 - _teams: ",_teams)
                                item.Teams = _teams.map((team) => team.trim());
                            }
                            else {
                                item.Teams = [];
                            }   

                            return item;
                        })
                
                        const _items = items;
                        _items.push(...tempItems)
                        setItems(_items);

                        //Todo: Remove this log line later

                        if (items.length === itemIds.length) {
                            let i = 0;
                            const _assigneeList = items.map((item) => item.Assignee).filter((value, index, self) => self.indexOf(value) === index).sort((a,b) => a.localeCompare(b)).map((item) => {return {label: item, value: item}});
                            setAssigneeList(_assigneeList);
                            setIsLoaded(true);
                        }
                    }
                );
                first = first + batchSize;
            }

            

        }
        else {
            setIsLoaded(true);
            setItems([]);
        }
    // Will not trigger this on items - it is just used to write it
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, itemIds]);
    //#endregion WorkItems
            
    //#endregion useEffects

    if (auth?.un === "" && auth.pw === ""){
        // Show Login
        return(
            <>
                <div>Missing Username and password</div>
                <LoginAdo updateAuth={updateAuth}></LoginAdo>
            </>
        )
    } 
    else if (isLoaded) {
        debugger;
        const showTeamFilter = (selectedProject === "56a5763c-99be-4053-929f-10e7fd0350b2");
        return (
            <>
                    <ToolBarAdo 
                    projects={projects.filter((item)=> {return  (hideProjects.indexOf(item.id) === -1)}).sort((a, b) => a.label > b.label ? 1 : -1)}
                    selectedProject={selectedProject}
                    projectOnChange={(event)=> {
                        store.set("SelectedProject", event.value);
                        setSelectedProject(event.value);
                        const _itemQuery = tConst.AZURE_Q1+ (event?.value !== "" ? tConst.AZURE_Q_PROJECT+" '"+event.label+"' " : "") + tConst.AZURE_Q_ORDER_BY;
                        console.log("_itemQuery: "+ _itemQuery);
                        setItemQuery({query:_itemQuery});}} 
                    openOnClickStatus={openOnClickStatus}
                    setOpenOnClickStatus={(value) => {        
                        store.set("openOnClickStatus", value);
                        setOpenOnClickStatus(value);
                    }}   
                    onRefreshClick={()=> setRefresh(Date.now())}
                    onLogout={() => {updateAuth({un:"", pw: ""})}}
                    viewId={viewId ? viewId : tConst.VIEW_ALL_PLANNED}
                    viewOnChange={(event) => {
                        //Set to persistant store
                        store.set("SelectedViewId", event.value);
                        setViewId(event.value)
                    }}
                    assigneeList={[{ label: tConst.ADO_ASSIGNED_ALL, value: tConst.ADO_ASSIGNED_ALL }, ...assigneeList]}
                    assigneeId={assigneeId}
                    assigneeOnChange={(event) => {
                        setAssigneeId(event.value)
                    }}
                    teamsId={teamsId}
                    teamsOnChange={(event) => {
                        setTeamsId(event.value)
                    }}
                    showTeamsFilter={showTeamFilter}



                    onHideProject={(projectId) => {
                        console.log("2024-05-23 - projectId" + projectId)
                        if (hideProjects.indexOf(projectId) == -1) {
                            const b = [...hideProjects, projectId];
                            store.set("hideProjects", JSON.stringify(b));
                        
                            setHideProjects(b);
                            
                        }
                        console.log("2024-05-23 - hideProjects" + JSON.stringify(hideProjects, null, 3))

                    }}
                    />
                   <TimelineAdo 
                    items={items} 
                    viewId={viewId}
                    assigneeFilterName={assigneeId}
                    teamsFilterName={teamsId}
                    openOnClickStatus={openOnClickStatus}
                    showChartMenu={showChartMenu} 
                    auth={auth}
                    setShowChartMenu={setShowChartMenu}
                    onMoveIteration={(selectedTicket, numberOfDays) => {
                        AzureService.moveTicketIteration(auth, selectedTicket, numberOfDays).then(() => {
                            setShowChartMenu(false);
                            setRefresh(Date.now());
                          });                    
                    }}
                    onExtendIteration={(selectedTicket, numberOfDays) => {
                        console.log("onExtendIteration clicked ticketID: "+ selectedTicket.id + ", numberOfDays: "+ numberOfDays )
                        AzureService.extendTicketIterations(auth,selectedTicket, numberOfDays).then(() => {
                            setShowChartMenu(false);
                            setRefresh(Date.now());
                        });
                    }}
                    Search={(ticketId) => {
                        console.log("User clicked search: "+ticketId);
                        return AzureService.getItem(auth, ticketId);
                    }}
                    onAddTicketToSprint={
                        (ticketId, StartDate, EndDate) => {
                            return AzureService.setIteration(auth,ticketId, StartDate, EndDate);
                        }
                    }
                    onUpdateTicket={(ticketId, data) => {
                        return AzureService.onUpdateTicket(auth, ticketId, data);
                    }}

                    onSetRefresh={() => {setRefresh(Date.now())}}
/*
                    onAddChildrenToTimeline={(selectedTicket) =>{
                        console.log("onAddChildrenToTimeline: "+JSON.stringify(selectedTicket, null, 4));
                        AzureService.AddChildrenToTimeline(auth,selectedTicket).then(() => {
                            setShowChartMenu(false);
                            setRefresh(Date.now());
                        });
            
                    }}
                    onPlanningToChildren={(selectedTicket)=>{console.log("onPlanningToChildren: "+JSON.stringify(selectedTicket, null, 4))}}
                    */
  
               />


        </>
        );
    }
    else {
        return(
            <div>Loading...</div>
        )
    }
        
};

export default TimelinePageAdo;
